import './App.css';
import Sidebar from './Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCookies } from 'react-cookie';
import './Main.css';
import { FaSearch } from "react-icons/fa";
import Background from './Background';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useParams, useHistory } from 'react-router-dom';

function PropertyList() {

    const [cookies, setCookie] = useCookies(['user']);
    const [propertyList,setPropertyList] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [lastPage,setLastPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [buy,setBuy] = useState(1);
    const [type, setType] = useState(0);
    const [empty, setEmpty] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const getPropertyList = () => {
        var data = new FormData();
        if(buy == 1) {
            data.append("buy", 0);
            data.append("rent", 1);
        } else {
            data.append("buy",1);
            data.append("rent", 0);
        }
        
        if(type == 0) {
            data.append("home", 1);
            data.append("apartment", 0);
            data.append("commercial", 0);
            data.append("coworking_space",0);
        } else if(type == 1) {
            data.append("home", 0);
            data.append("apartment", 1);
            data.append("commercial", 0);
            data.append("coworking_space",0);
        } else if(type == 2) {
            data.append("home", 0);
            data.append("apartment", 0);
            data.append("commercial", 1);
            data.append("coworking_space",0);
        } else if(type == 3) {
	    data.append("home",0);
            data.append("apartment",0);
            data.append("commercial",0);
            data.append("coworking_space",1);
	}
       
        if(empty == 0) {
            data.append("empty", 1);
            data.append("furnished", 0);
        } else if(empty == 1) {
            data.append("empty", 0);
            data.append("furnished", 1);
        }
        
        data.append("new",0);
        data.append("user_id", cookies.id);
        data.append("page",page);
        data.append("page_size",pageSize);
        if(searchTerm != "") {
            data.append("search", searchTerm);
        }
        setIsLoading(true);
        fetch('https://api.connecker.com/api/get_properties', {
            method: 'POST',
            body: data
        }).then(res => {
            setIsLoading(false);
            if(!res.ok) {
                throw Error('Could not fetch');
            }
            return res.json();
         }).then(data => {
            setIsLoading(false);
            if(data.response_code == 200) {
               setPropertyList(data.data);
               setPage(data.page);
               setLastPage(data.last_page);
            } else if(data.response_code == 404) {
                setPropertyList([]);
            }
         });
    }

    const nextPage = () => {
        setPage(page+1);
    }

    const previousPage = () => {
        setPage(page-1);
    }

    useEffect(() => {
        const abortController = new AbortController();
        getPropertyList();
       },[page,pageSize, searchTerm, buy, type, empty]);

    return(
        <div className="content">
          <div className="row pb-2">
                <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                    <Sidebar></Sidebar>
                </div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                    <Background></Background>
                    <h4 className="heading" style={{ color:"white", marginTop:"50px" }}>Property List</h4>
                    <div className="card" style={{borderColor:"white"}}>
                        <div className="table-responsive">
                            <table style={{width:"100%"}} className="table table-borderless">
                                <thead>
                                    <tr>
                                        <td>
                                            <div class="d-flex paragraph">
                                                Show
                                                
                                                <select name="entries" id="entries" style={{marginLeft:"5px", marginRight:"5px"}} onChange={({ target: { value } }) => setPageSize(value)}>
                                                    <option value="10">10</option>
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                                
                                                Entries
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex paragraph">
                                                Sale/Rent
                                                
                                                <select name="buy" id="buy" style={{marginLeft:"5px"}} onChange={({ target: { value } }) => setBuy(value)}>
                                                    <option value="0">Buy</option>
                                                    <option value="1" selected>Rent</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex paragraph">
                                                Type
                                                
                                                <select name="type" id="type" style={{marginLeft:"5px"}} onChange={({ target: { value } }) => setType(value)}>
                                                    <option value="0" selected>Home</option>
                                                    <option value="1">Apartment</option>
                                                    <option value="2">Commercial</option>
                                                    <option value="3">Coworking Space</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex paragraph">
                                                Empty/Furnished
                                                
                                                <select name="empty" id="empty" style={{marginLeft:"5px"}} onChange={({ target: { value } }) => setEmpty(value)}>
                                                    <option value="0">Empty</option>
                                                    <option value="1" selected>Furnished</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td style={{textAlign:"right"}} colspan="8">
                                            <div className="paragraph">
                                                Search
                                                
                                                <input type="text" id="listSearch" style={{marginLeft:"5px"}} onChange={({ target: { value } }) => setSearchTerm(value)}></input>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr style={{ color:"grey" }} className="paragraph">
                                        <td>
                                            <b>#</b>
                                        </td>
                                        <td>
                                            <b>Property</b>
                                        </td>
                                        <td>
                                            <b>Address</b>
                                        </td>
                                        <td>
                                            <b>Beds</b>
                                        </td>
                                        <td>
                                            <b>Sq.Ft.</b>
                                        </td>
                                        <td>
                                            <b>Agent</b>
                                        </td>
                                        <td>
                                            <b>Sale/Rent</b>
                                        </td>
                                        <td>
                                            <b>Price</b>
                                        </td>
                                        <td>
                                            <b>Actions</b>
                                        </td>
                                    </tr>
                                </thead>
                                {
                                    propertyList.map((property, i) => (
                                            <tr className="paragraph">
                                                <td>
                                                {i+1}
                                                </td>
                                                <td>
                                                    {property.name}
                                                </td>
                                                <td>
                                                    {property.address}
                                                </td>
                                                <td>
                                                    {property.beds}&nbsp;BHK
                                                </td>
                                                <td>
                                                    {property.project_area}
                                                </td>
                                                <td>
                                                    {property.email}
                                                </td>
                                                <td>
                                                        { property.buy == 0 ? <p style={{ width:"5rem", border:"2px solid orange", borderRadius:"16px", color:"orange" }}><center><b>Rent</b></center></p> : <p style={{ width:"5rem", border:"2px solid green", borderRadius:"16px", color:"green" }}><center><b>Sale</b></center></p> }
                                                </td>
                                                <td>
                                                    { property.price }
                                                </td> 
                                                <td>
                                                    <Link to={`/property/${property.id}`}>View</Link>
                                                </td>
                                            </tr>
                                    ))
                                }
                            </table>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-between">
                            <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div>
                                    {((page > 1) && propertyList.length > 0) ? <button type="button" onClick={ previousPage } class="btn btn-outline-secondary">Previous</button> : <div></div> }
                                    {((lastPage > page) && propertyList.length > 0) ? <button type="button" style={{ color:"#FF4500" }} onClick={ nextPage } class="btn btn-outline-secondary">Next</button> : <div></div>}
                                </div>
                            </div>
                        </div> 
                </div>
            </div>
        </div>
    );
}

export default PropertyList;
