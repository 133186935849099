import './App.css';
import Sidebar from './Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCookies } from 'react-cookie';
import './Main.css';
import { FaSearch } from "react-icons/fa";
import Background from './Background';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

function UserList() {
    const [cookies, setCookie] = useCookies(['user']);
    const [userList,setUserList] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [lastPage,setLastPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const getUserList = () => {
        var data = new FormData();
        
        data.append("sort_by","created_at");
        data.append("sorting_order", "desc");
        data.append("page",page);
        data.append("page_size",pageSize);
        if(searchTerm != "") {
            data.append("search_term", searchTerm);
        }
        setIsLoading(true);
        fetch('https://api.connecker.com/api/get_all_users', {
            method: 'POST',
            body: data
        }).then(res => {
            setIsLoading(false);
            if(!res.ok) {
                throw Error('Could not fetch');
            }
            return res.json();
         }).then(data => {
            setIsLoading(false);
            if(data.response_code == 200) {
               setUserList(data.data.data);
               setPage(data.page);
               setLastPage(data.last_page);
            } else if(data.response_code == 404) {
                setUserList([]);
            }
         });
    }

    const nextPage = () => {
        setPage(page+1);
    }

    const previousPage = () => {
        setPage(page-1);
    }

    useEffect(() => {
        const abortController = new AbortController();
        getUserList();
       },[page,pageSize, searchTerm]);

    return(
        <div className="content">
            <div className="row pb-2">
                <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                    <Sidebar></Sidebar>
                </div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                        <Background></Background>
                        <h4 className="heading" style={{ color:"white", marginTop:"50px" }}>User List</h4>
                        <div className="card" style={{borderColor:"white"}}>
                            <div className="table-responsive">
                                <table style={{width:"100%"}} className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <td>
                                                <div class="d-flex paragraph">     
                                                    Show
                                                    <select name="entries" id="entries" onChange={({ target: { value } }) => setPageSize(value)}>
                                                        <option value="10">10</option>
                                                        <option value="20">20</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                    Entries
                                                </div>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td style={{textAlign:"right"}} colspan="6">
                                                Search
                                                &nbsp;
                                                <input type="text" id="listSearch" onChange={({ target: { value } }) => setSearchTerm(value)}></input>
                                            </td>  
                                        </tr>
                                    <tr className="paragraph" style={{ color:"grey" }}>
                                        <td>
                                            <b>#</b>
                                        </td>
                                        <td>
                                            <b>Name</b>
                                        </td>
                                        <td>
                                            <b>Email</b>
                                        </td>
                                        <td>
                                            <b>Mobile</b>
                                        </td>
                                        <td>
                                            <b>City</b>
                                        </td>
                                        <td>
                                            <b>Agent</b>
                                        </td>
                                        <td>
                                            <b>Deal</b>
                                        </td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            userList.map((user, i) => (
                                                    <tr className="paragraph">
                                                        <td>
                                                        {i+1}
                                                        </td>
                                                        <td>
                                                            {user.middle_name != null ? (user.first_name + " " + user.middle_name + " " + user.last_name) : ""}
                                                            {user.middle_name == null ? (user.first_name + " " + user.last_name) : ""}
                                                        </td>
                                                        <td>
                                                            {user.email}
                                                        </td>
                                                        <td>
                                                            {user.mobile}
                                                        </td>
                                                        <td>
                                                            {user.city}
                                                        </td>
                                                        <td>
                                                            {user.email}
                                                        </td> 
                                                        <td>
                                                            <Link to={`/user_details/${user.id}`}>View</Link>
                                                        </td>
                                                    </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-between">
                            <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                               
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div>
                                    {((page > 1) && userList.length > 0) ? <button type="button" onClick={ previousPage } class="btn btn-outline-secondary">Previous</button> : <div></div> }
                                    {((lastPage > page) && userList.length > 0) ? <button type="button" style={{ color:"#FF4500" }} onClick={ nextPage } class="btn btn-outline-secondary">Next</button> : <div></div>}
                                </div>
                            </div>
                        </div>                
                    </div>
            </div>
        </div>
    );
}

export default UserList;