import './App.css';
import './Welcome.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import BlogList from './BlogList';
import useFetch from './useFetch';
import { FaEye,FaEyeSlash } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyComponent from 'react-fullpage-custom-loader'
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import conneckerLogo3 from './images/connecker_logo_3.png';

const Home = () => {
    const [email,setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [cookies, setCookie] = useCookies(['user']);
    const myStyle={
        background:"linear-gradient(0deg, rgba(0 0 0 / 54%), rgba(0 0 0 / 54%)), url('/images/background_2.jpeg')",
        height:'100vh',
        margin:'0px',
        backgroundPosition:'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    };
    const formStyle={
        width:"300px",
    };
    const login = (e) => {
        e.preventDefault();
        var data = new FormData();
        data.append("email", email);
        data.append("password", password);
        setIsLoading(true);
        fetch('https://api.connecker.com/api/admin_login', {
            method: 'POST',
            body: data
        }).then(res => {
            setIsLoading(false);
            if(!res.ok) {
                throw Error('Could not fetch');
            }
            return res.json();
         }).then(data => {
            setIsLoading(false);
            setCookie('admin', data.data.user.admin, { path: '/' });
            setCookie('created_at', data.data.user.created_at, { path: '/' });
            setCookie('email', data.data.user.email, { path: '/' });
            setCookie('email_verified_at', data.data.user.email_verified_at, { path: '/' });
            setCookie('firebase_token', data.data.user.firebase_token, { path: '/' });
            setCookie('first_name', data.data.user.first_name, { path: '/' });
            setCookie('id', data.data.user.id, { path: '/' });
            setCookie('last_name', data.data.user.last_name, { path: '/' });
            setCookie('latitude', data.data.user.latitude, { path: '/' });
            setCookie('longitude', data.data.user.longitude, { path: '/' });
            setCookie('middle_name', data.data.user.middle_name, { path: '/' });
            setCookie('mobile', data.data.user.mobile, { path: '/' });
            setCookie('online', data.data.user.online, { path: '/' });
            setCookie('profile_pic', data.data.user.profile_pic, { path: '/' });
            setCookie('updated_at', data.data.user.updated_at, { path: '/' });
            setCookie('token', data.data.token, { path: '/' });
            console.log(data);
            toast(data.message);
            if(data.response_code == 200) {
                history.push("/main");
            }
         });
    }

    function myFunction() {
        var x = document.getElementById("passwordInput");
        var t = document.getElementById("togglePassword");
        var ts = document.getElementById("togglePasswordSlash");
        if (x.type === "password") {
            x.type = "text";
            t.style.display = 'none';
            ts.style.display = 'block';
        } else {
            x.type = "password";
            t.style.display = 'block';
            ts.style.display = 'none';
        }
      }

    useEffect(() => {
        const abortController = new AbortController();
        setCookie('admin', "", { path: '/' });
        setCookie('created_at', "", { path: '/' });
        setCookie('email', "", { path: '/' });
        setCookie('email_verified_at', "", { path: '/' });
        setCookie('firebase_token', "", { path: '/' });
        setCookie('first_name', "", { path: '/' });
        setCookie('id', "", { path: '/' });
        setCookie('last_name', "", { path: '/' });
        setCookie('latitude', "", { path: '/' });
        setCookie('longitude', "", { path: '/' });
        setCookie('middle_name', "", { path: '/' });
        setCookie('mobile', "", { path: '/' });
        setCookie('online', "", { path: '/' });
        setCookie('profile_pic', "", { path: '/' });
        setCookie('updated_at', "", { path: '/' });
    },[]);

    return (
        <div style={myStyle} >
            <center>
                <img style={{ marginTop:"100px" }} width="200" height="auto" src={conneckerLogo3}></img>
                <br></br><br></br>
                <h5 className="subHeading" style={{ color: "white" }}>Log In</h5>
                <br></br><br></br>
                <form style={formStyle} onSubmit={login}>
                    <div class="form-outline">
                        <div className="d-flex paragraph" style={{ border:"1px solid #FFF", borderRadius:"50px" }}>
                            <input className="transparentInputClass" style={{ color:"white", border:"none", borderRadius:"50px" }}  type="text" id="emailInput" class="form-control" placeholder='Enter Email ID' value={email} onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                        <br></br>
                        <div className="d-flex paragraph" style={{ border:"1px solid #FFF", borderRadius:"50px" }}>
                            <input className="transparentInputClass" style={{ color:"white", border:"none", borderRadius:"50px" }}  type="password" id="passwordInput" class="form-control" placeholder='Enter Password' value={password} onChange={(e) => setPassword(e.target.value)}/>
                            <FaEye onClick={() => { myFunction(); }} style={{ display:"block", stroke:"black", color:"white", position:"absolute",marginLeft:"270px",marginTop:"10px" }} id="togglePassword"></FaEye>
                            <FaEyeSlash onClick={() => { myFunction(); }} style={{ display:"none", stroke:"black", color:"white", position:"absolute",marginLeft:"270px",marginTop:"10px" }} id="togglePasswordSlash"></FaEyeSlash>
                        </div>
                        <br></br><br></br>
                        <button className="btn btn-primary" style={{ borderRadius:"50px", width:"300px", backgroundColor:"orange", fontFamily:"FuturaBook" }}>Sign In</button>
                    </div>
                </form>
            </center>
            <ToastContainer />
            { isLoading ? <MyComponent style={{ fontFamily:"FuturaBook", }} sentences={["Loading..."]} /> : <div></div>}
        </div>
    );
}

export default Home;