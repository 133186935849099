import './App.css';
import Sidebar from './Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCookies } from 'react-cookie';
import './Main.css';
import { FaMapMarkerAlt, FaMicrosoft, FaBed, FaCarAlt, FaHome, FaCheckCircle, FaCloudUploadAlt } from "react-icons/fa";
import { useParams, useHistory } from 'react-router-dom';
import Background from './Background';
import { useState, useEffect, useCallback } from 'react';
import './TableStyle.css';
import { MapContainer, TileLayer } from 'react-leaflet';
import {useDropzone} from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';

function AddProperty() {
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        var pictureList = [];
        var uploadPictureList = [];
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            uploadPictureList.push(file);
            setUploadPicturesList(uploadPictureList);
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.addEventListener('load', (e) => {
                pictureList.push(e.target.result);
                setPictures(pictureList);
            });
            reader.readAsDataURL(file);
          })
        
      }, []);

    const brochureHandler = (e) => {
        if (e.target.files.length > 0) {
            let filename = e.target.files[0].name;
            setBrochure(e.target.files[0]);
        }
    }

    const videoHandler = (e) => {
        if (e.target.files.length > 0) {
            let filename = e.target.files[0].name;
            setVideo(e.target.files[0]);
        }
    }

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
    const [pictures, setPictures] = useState(null);
    const [brochure, setBrochure] = useState(null);
    const [video, setVideo] = useState(null);
    const [uploadPicturesList, setUploadPicturesList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [buy, setBuy] = useState(0);
    const [rent, setRent] = useState(0);
    const [bedroom, setBedroom] = useState(0);
    const [bathroom, setBathroom] = useState(0);
    const [livingroom, setLivingroom] = useState(0);
    const [kitchen, setKitchen] = useState(0);
    const [parkingArea, setParkingArea] = useState(0);
    const [frontYard, setFrontYard] = useState(0);
    const [backYard, setBackYard] = useState(0);
    const [balcony, setBalcony] = useState(0);
    const [gym, setGym] = useState(0);
    const [lift, setLift] = useState(0);
    const [floor, setFloor] = useState(0);
    const [family, setFamily] = useState(0);
    const [bachelor, setBachelor] = useState(0);
    const [company, setCompany] = useState(0);
    const [doesNotMatter, setDoesNotMatter] = useState(0);
    const [sofa, setSofa] = useState(0);
    const [kingSizedBed, setKingSizedBed] = useState(0);
    const [queenSizedBed, setQueenSizedBed] = useState(0);
    const [mattress, setMattress] = useState(0);
    const [convertibleSofa, setConvertibleSofa] = useState(0);
    const [wardrobe, setWardrobe] = useState(0);
    const [console, setConsole] = useState(0);
    const [mirror, setMirror] = useState(0);
    const [chair, setChair] = useState(0);
    const [table, setTable] = useState(0);
    const [tv, setTv] = useState(0);
    const [cable, setCable] = useState(0);
    const [radio, setRadio] = useState(0);
    const [alarmClock, setAlarmClock] = useState(0);
    const [wifi, setWifi] = useState(0);
    const [fridge, setFridge] = useState(0);
    const [oven, setOven] = useState(0);
    const [microwave, setMicrowave] = useState(0);
    const [airConditioner, setAirConditioner] = useState(0);
    const [ventilatorFan, setVentilatorFan] = useState(0);
    const [dryer, setDryer] = useState(0);
    const [hairDryer, setHairDryer] = useState(0);
    const [dishwasher, setDishwasher] = useState(0);
    const [washingMachine, setWashingMachine] = useState(0);
    const [kettle, setKettle] = useState(0);
    const [coffeeMachine, setCoffeeMachine] = useState(0);
    const [teapot, setTeapot] = useState(0);
    const [waterHeater, setWaterHeater] = useState(0);
    const [cooker, setCooker] = useState(0);
    const [generator, setGenerator] = useState(0);
    const [waterTank, setWaterTank] = useState(0);
    const [keeper, setKeeper] = useState(0);
    const [maid, setMaid] = useState(0);
    const [electricity, setElectricity] = useState(0);
    const [bedsheets, setBedsheets] = useState(0);
    const [bathTowels, setBathTowels] = useState(0);
    const [description, setDescription] = useState(0);
    const [budget, setBudget] = useState(0);
    const [bedrooms, setBedrooms] = useState(0);
    const [bathrooms, setBathrooms] = useState(0);
    const [beds, setBeds] = useState(0);
    const [empty, setEmpty] = useState(0);
    const [name, setName] = useState(0);
    const [location, setLocation] = useState(0);
    const [apartment, setApartment] = useState(0);
    const [house, setHouse] = useState(0);
    const [coWorkingSpace, setCoWorkingSpace] = useState(0);
    const [commercial, setCommercial] = useState(0);
    const [cupboards, setCupboards] = useState(0);
    const [intercom, setIntercom] = useState(0);
    const [userType, setUserType] = useState(0);
    const [duration, setDuration] = useState(15);
    const [projectArea, setProjectArea] = useState(0);

    const createProperty = () => {
        var data = new FormData();
        data.append("user_id",11);
        data.append("name",name);
        data.append("city_id",0);
        data.append("district_id",0);
        data.append("email","help@connecker.com");
        data.append("town_id",0);
        data.append("bedroom",bedroom);
        data.append("bathroom",bathroom);
        data.append("livingroom",livingroom);
        data.append("kitchen",kitchen);
        data.append("parking_area",parkingArea);
        data.append("front_yard",frontYard);
        data.append("back_yard",backYard);
        data.append("balcony",balcony);
        data.append("gym",gym);
        data.append("lift",lift);
        data.append("floor",floor);
        data.append("p_type_id",0);
        data.append("project_area",projectArea);
        data.append("budget",budget);
        data.append("description",description);
        data.append("duration",duration);
        data.append("contact",0);
        data.append("telephone",0);
        if(buy == 0) {
            data.append("buy",0);
            data.append("rent",1);
        } else if(rent == 0) {
            data.append("buy",1);
            data.append("rent",0);
        }
        else if(buy == 1) {
            data.append("buy",1);
            data.append("rent",0);
        } else if(rent == 1) {
            data.append("buy",0);
            data.append("rent",1);
        }
        if(commercial == 1) {
            data.append("commercial",1);
            data.append("home",0);
            data.append("apartment",0);
            data.append("coworking_space",0);
        } else if(house == 1) {
            data.append("commercial",0);
            data.append("home",1);
            data.append("apartment",0);
            data.append("coworking_space",0);
        } else if(apartment == 1) {
            data.append("commercial",0);
            data.append("home",0);
            data.append("apartment",1);
            data.append("coworking_space",0);
        } else if(coWorkingSpace == 1) {
            data.append("commercial",0);
            data.append("home",0);
            data.append("apartment",0);
            data.append("coworking_space",1);
        }
        data.append("empty",empty);
        data.append("furnished",(empty == 0 ? 1 : 0));
        data.append("price",budget);
        data.append("discount",0);
        data.append("discount_percent",0);
        data.append("address",location);
        data.append("bedrooms",bedrooms);
        data.append("bathrooms",bathrooms);
        data.append("beds",beds);
        data.append("family",family);
        data.append("bachelor",bachelor);
        data.append("company",company);
        data.append("does_not_matter",doesNotMatter);
        data.append("sofa",sofa);
        data.append("king_sized_bed",kingSizedBed);
        data.append("queen_sized_bed",queenSizedBed);
        data.append("mattress",mattress);
        data.append("convertible_sofa",convertibleSofa);
        data.append("wardrobe",wardrobe);
        data.append("console",console);
        data.append("mirror",mirror);
        data.append("chair",chair);
        data.append("furniture_table",table);
        data.append("tv",tv);
        data.append("cable",cable);
        data.append("radio",radio);
        data.append("alarm_clock",alarmClock);
        data.append("wifi",wifi);
        data.append("fridge",fridge);
        data.append("oven",oven);
        data.append("microwave",microwave);
        data.append("airconditioner",airConditioner);
        data.append("ventilatorfan",ventilatorFan);
        data.append("dryer",dryer);
        data.append("hairdryer",hairDryer);
        data.append("dishwasher",dishwasher);
        data.append("washingmachine",washingMachine);
        data.append("kettle",kettle);
        data.append("coffeemachine",coffeeMachine);
        data.append("teapot",teapot);
        data.append("waterheater",waterHeater);
        data.append("cooker",cooker);
        data.append("generator",generator);
        data.append("watertank",waterTank);
        data.append("keeper",keeper);
        data.append("maid",maid);
        data.append("bedsheets",bedsheets);
        data.append("bathtowels",bathTowels);
        data.append("projecttype",0);
        data.append("electricity",electricity);
        data.append("cupboards",cupboards);
        data.append("intercom",intercom);
        data.append("alarmclock",alarmClock);
        data.append("user_type",userType);
        if(uploadPicturesList != null) {
            uploadPicturesList.forEach((file,i) => {
                data.append("pictures["+i+"]",file);
            });
        }
        if(brochure != null) {
            data.append("brochure",brochure);
        }
        if(video != null) {
            data.append("video",video);
        }
        setIsLoading(true);
        fetch('https://api.connecker.com/api/add_property', {
            method: 'POST',
            body: data
        }).then(res => {
            setIsLoading(false);
            if(!res.ok) {
                throw Error('Could not fetch');
            }
            return res.json();
         }).then(async(data) => {
            setIsLoading(false);
            window.console.log(data.success);
            if(data.success == true) {
                toast("Property created successfully");
		//window.history.push("/property/"+data.data.id);
            } else if(data.response_code == 404) {
                toast(data.message);
            }
         });
    }

    return(
        <div className="content">
            <div className="row d-flex justify-content-center">
                <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                    <Sidebar></Sidebar>
                </div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                    <Background></Background>
                    <h4 className="heading" style={{ color:"white", marginTop:"50px" }}>Add Property</h4>
                    <div className="row justify-content-start">
                        <div className="col-12">
                            <div class="card" style={{width:"100%"}}>
                                <div class="card-body">
                                    <div className="d-flex subHeading"><h6 style={{ color:"orange" }}>Basic</h6>&nbsp;<h6>Information</h6></div>
                                    <input className="paragraph" type="text" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px" }} onChange={(e) => setName(e.target.value)} placeholder="Property Name"></input>
                                    <input className="paragraph" type="text" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px" }} onChange={(e) => setLocation(e.target.value)}  placeholder="Property Location"></input>
                                    <br></br>
                                    {/*<textarea style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px" }} placeholder="Property Description" rows="4" cols="40"></textarea>*/}
                                    <hr></hr>
                                    <h6 className="subHeading" >PROPERTY INFORMATION</h6>
                                    <div className="row d-flex paragraph">
                                        <div className="col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                            <input type="radio" name="type_of_property" value="1" onChange={(e) => setApartment(e.target.value)} />&nbsp;Apartment
                                        </div>
                                        <div className="col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                        <input type="radio" name="type_of_property" value="1" onChange={(e) => setHouse(e.target.value)} />&nbsp;House
                                        </div>
                                        <div className="col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                            <input type="radio" name="type_of_property" value="1" onChange={(e) => setCoWorkingSpace(e.target.value)} />&nbsp;Coworking Space
                                        </div>
                                        <div className="col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                            <input type="radio" name="type_of_property" value="1" onChange={(e) => setCommercial(e.target.value)} />&nbsp;Commercial Building
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row d-flex paragraph">
                                        <div className="col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                            <input type="radio" name="sale_or_rent" value="1" onChange={(e) => setBuy(e.target.value)} />&nbsp;For Sale
                                        </div>
                                        <div className="col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                            <input type="radio" name="sale_or_rent" value="1" onChange={(e) => setRent(e.target.value)} />&nbsp;For Rent
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row d-flex paragraph">
                                        <div className="col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                            <input type="radio" name="empty_or_furnished" value="1" onChange={(e) => setEmpty(e.target.value)} />&nbsp;Empty Property
                                        </div>
                                        <div className="col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                            <input type="radio" name="empty_or_furnished" value="1" onChange={(e) => setEmpty(e.target.value)} />&nbsp;Furnished Property
                                        </div>
                                    </div>
                                    <br></br>
                                    <input className="paragraph" type="text" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px" }} onChange={(e) => setBudget(e.target.value)} placeholder="Budget FCFA(XOF)"></input>
                                    <input className="paragraph" type="text" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px" }} onChange={(e) => setProjectArea(e.target.value)} placeholder="Project Area (Sq. Ft.)"></input>
                                    <br></br><br></br>
                                    <h6 className="subHeading">ROOMS AND BEDROOMS</h6>
                                    <div className="container">
                                        <div className="row d-flex">
                                            <div className="col-xl-4 col-xxl-4">
                                                <div className="row d-flex paragraph">
                                                    <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                                        <div style={{ marginTop:"10px" }}>Bedrooms&nbsp;&nbsp;&nbsp;</div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                                        <input type="number" onChange={(e) => setBedrooms(e.target.value)} min="0" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 and event.charCode <= 57" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px" }}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-xxl-4">
                                                <div className="row d-flex paragraph">
                                                    <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                                        <div style={{ marginTop:"10px" }}>Bathrooms&nbsp;&nbsp;&nbsp;</div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                                        <input type="number" onChange={(e) => setBathrooms(e.target.value)} min="0" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 and event.charCode <= 57" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px" }}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-xxl-4">
                                                <div className="row d-flex paragraph">
                                                    <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                                        <div style={{ marginTop:"10px" }}>Beds&nbsp;&nbsp;&nbsp;</div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                                        <input type="number" onChange={(e) => setBeds(e.target.value)} min="0" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 and event.charCode <= 57" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px" }}/>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <br></br>
                                    <h6 className="subHeading">AMENITIES</h6>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="bedroom" value="1" onChange={(e) => setBedroom(e.target.value)} />&nbsp;Bedroom</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="bathroom" value="1" onChange={(e) => setBathroom(e.target.value)} />&nbsp;Bathroom</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="livingroom" value="1" onChange={(e) => setLivingroom(e.target.value)} />&nbsp;Living Room</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="kitchen" value="1" onChange={(e) => setKitchen(e.target.value)} />&nbsp;Kitchen</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="parking_area" value="1" onChange={(e) => setParkingArea(e.target.value)} />&nbsp;Parking Area</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="frontyard" value="1" onChange={(e) => setFrontYard(e.target.value)} />&nbsp;Front Yard</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="backyard" value="1" onChange={(e) => setBackYard(e.target.value)} />&nbsp;Back Yard</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="balcony" value="1" onChange={(e) => setBalcony(e.target.value)} />&nbsp;Balcony</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="gym" value="1" onChange={(e) => setGym(e.target.value)} />&nbsp;Gym</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="lift" value="1" onChange={(e) => setLift(e.target.value)} />&nbsp;Lift</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="floor" value="1" onChange={(e) => setFloor(e.target.value)} />&nbsp;Floor</div>
                                            </div>
                                            <div class="col"></div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <h6 className="subHeading">PREFERRED TENANTS</h6>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="bedroom" value="1" onChange={(e) => setFamily(e.target.value)} />&nbsp;Family</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="bathroom" value="1" onChange={(e) => setBachelor(e.target.value)} />&nbsp;Bachelor</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="livingroom" value="1" onChange={(e) => setCompany(e.target.value)} />&nbsp;Company</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="kitchen" value="1" onChange={(e) => setDoesNotMatter(e.target.value)} />&nbsp;Does Not Matter</div>
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <h6 className="subHeading">FURNITURE</h6>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="sofa" value="1" onChange={(e) => setSofa(e.target.value)} />&nbsp;Sofa</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="kingsizedbed" value="1" onChange={(e) => setKingSizedBed(e.target.value)} />&nbsp;King Sized Bed</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="queensizedbed" value="1" onChange={(e) => setQueenSizedBed(e.target.value)} />&nbsp;Queen Sized Bed</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="mattress" value="1" onChange={(e) => setMattress(e.target.value)} />&nbsp;Mattress</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="convertiblesofa" value="1" onChange={(e) => setConvertibleSofa(e.target.value)} />&nbsp;Convertible Sofa</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="wardrobe" value="1" onChange={(e) => setWardrobe(e.target.value)} />&nbsp;Wardrobe</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="console" value="1" onChange={(e) => setConsole(e.target.value)} />&nbsp;Console</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="mirror" value="1" onChange={(e) => setMirror(e.target.value)} />&nbsp;Mirror</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="chair" value="1" onChange={(e) => setChair(e.target.value)} />&nbsp;Chair</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="table" value="1" onChange={(e) => setTable(e.target.value)} />&nbsp;Table</div>
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <h6 className="subHeading">EQUIPMENT</h6>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="tv" value="1" onChange={(e) => setTv(e.target.value)} />&nbsp;TV</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="cable" value="1" onChange={(e) => setCable(e.target.value)} />&nbsp;Cable</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="radio" value="1" onChange={(e) => setRadio(e.target.value)} />&nbsp;Radio</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="alarmclock" value="1" onChange={(e) => setAlarmClock(e.target.value)} />&nbsp;Alarm Clock</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="wifi" value="1" onChange={(e) => setWifi(e.target.value)} />&nbsp;Wifi</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="fridge" value="1" onChange={(e) => setFridge(e.target.value)} />&nbsp;Fridge</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="oven" value="1" onChange={(e) => setOven(e.target.value)} />&nbsp;Oven</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="microwave" value="1" onChange={(e) => setMicrowave(e.target.value)} />&nbsp;Microwave</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="airconditioner" value="1" onChange={(e) => setAirConditioner(e.target.value)} />&nbsp;Air Conditioner</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="ventilatorfan" value="1" onChange={(e) => setVentilatorFan(e.target.value)} />&nbsp;Ventilator Fan</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="dryer" value="1" onChange={(e) => setDryer(e.target.value)} />&nbsp;Dryer</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="hairdryer" value="1" onChange={(e) => setHairDryer(e.target.value)} />&nbsp;Hair Dryer</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="dishwasher" value="1" onChange={(e) => setDishwasher(e.target.value)} />&nbsp;Dishwasher</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="washingmachine" value="1" onChange={(e) => setWashingMachine(e.target.value)} />&nbsp;Washing Machine</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="kettle" value="1" onChange={(e) => setKettle(e.target.value)} />&nbsp;Kettle</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="coffeemachine" value="1" onChange={(e) => setCoffeeMachine(e.target.value)} />&nbsp;Coffee Machine</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="teapot" value="1" onChange={(e) => setTeapot(e.target.value)} />&nbsp;Teapot</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="waterheater" value="1" onChange={(e) => setWaterHeater(e.target.value)} />&nbsp;Water Heater</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="cooker" value="1" onChange={(e) => setCooker(e.target.value)} />&nbsp;Cooker</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="generator" value="1" onChange={(e) => setGenerator(e.target.value)} />&nbsp;Generator</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="watertank" value="1" onChange={(e) => setWaterTank(e.target.value)} />&nbsp;Water Tank</div>
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <h6 className="subHeading">SERVICES</h6>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="keeper" value="1" onChange={(e) => setKeeper(e.target.value)} />&nbsp;Keeper</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="maid" value="1" onChange={(e) => setMaid(e.target.value)} />&nbsp;Maid</div>
                                            </div>
                                            <div class="col">
                                            </div>
                                        </div>
                                    </div>
                                    <br></br><br></br>
                                    <h6 className="subHeading">ELECTRICITY</h6>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="electricity" value="1" onChange={(e) => setElectricity(e.target.value)} />&nbsp;Electricity</div>
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <h6 className="subHeading">TISSUS</h6>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="bedsheets" value="1" onChange={(e) => setBedsheets(e.target.value)} />&nbsp;Bedsheets</div>
                                            </div>
                                            <div class="col">
                                                <div className="d-flex paragraph"><input type="checkbox" style={{ width:"20px",height:"20px" }} name="bathtowels" value="1" onChange={(e) => setBathTowels(e.target.value)} />&nbsp;Bathtowels</div>
                                            </div>
                                            <div class="col">
                                            </div>
                                        </div>
                                    </div>
                                    <br></br><br></br>
                                    <textarea className="paragraph" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px" }} onChange={(e) => setDescription(e.target.value)} placeholder="Property Description" rows="4" cols="30"></textarea>
                                    <br></br>
                                    <div className="d-flex">
                                        <div className="d-flex paragraph">
                                            <div style={{ marginTop:"15px" }}>Select Your Type&nbsp;&nbsp;</div>
                                            <select name="user_type" onChange={(e) => setUserType(e.target.value)} style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px" }}>
                                                <option value="0">Owner</option>
                                                <option value="1">Agent</option>
                                                <option value="2">Builder</option>
                                            </select>
                                        </div>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <div className="d-flex paragraph">
                                            <div style={{ marginTop:"15px" }}>Select Duration Days&nbsp;&nbsp;</div>
                                            <select name="duration_days" onChange={(e) => setDuration(e.target.value)} style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px" }}>
                                                <option value="15">15</option>
                                                <option value="30">30</option>
                                                <option value="45">45</option>
                                                <option value="60">60</option>
                                            </select>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <h6 className="subHeading">UPLOAD PICTURES</h6>
                                    <div className="container">
                                        <div className="row">
                                            {
                                                pictures != null ? pictures.map((picture, i) => (
                                                        <img className="img-fluid" src={picture} style={{ width:"auto",height:"100px",marginTop:"10px" }}></img>
                                                )) : ""
                                            }
                                        </div>
                                    </div>
                                    <br></br>
                                    <div {...getRootProps()} class="container" style={{ borderRadius:"16px", height:"12rem", backgroundColor:"#FCC981" }}>
                                        <input {...getInputProps()} />
                                            {
                                                !isDragActive ?
                                                <center>
                                                        <FaCloudUploadAlt style={{ width:"5rem", height:"5rem", color:"#FF4500" }}></FaCloudUploadAlt>
                                                        <br></br><br></br>
                                                        <h5><b className="paragraph">Drop Files Or Click To Upload</b></h5>
                                                </center>
                                                :
                                                <div></div>
                                            }
                                    </div>
                                    <br></br>
                                    <h6 className="subHeading">UPLOAD BROCHURE</h6>
                                    <input name="brochure" id="brochure" type="file" accept="application/pdf" onChange={brochureHandler}></input>
                                    <br></br><br></br>
                                    <button className="paragraph" onClick={() => createProperty()} style={{ color:"white",backgroundColor:"#FF4500",border:"1px solid #FF4500",borderRadius:"25px",padding:"10px",paddingLeft:"40px",paddingRight:"40px",margin:"5px" }}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default AddProperty;
