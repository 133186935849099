import './App.css';
import Sidebar from './Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Incrementer from './Incrementer';
import Looper from './Looper';
import { HashRouter,BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Create from './Create';
import BlogDetails from './BlogDetails';
import NotFound from './NotFound';
import Home from './Home';
import Main from './Main';
import PropertyList from './PropertyList';
import PropertyDetails from './PropertyDetails';
import PropertyDetailsOpen from './PropertyDetailsOpen';
import AddProperty from './AddProperty';
import PropertyListHome from './PropertyListHome';
import PropertyListApartment from './PropertyListApartment';
import PropertyListCommercial from './PropertyListCommercial';
import UserList from './UserList';
import UserDetails from './UserDetails';
import AddUser from './AddUser';
import ChatList from './ChatList';
import ChatDetails from './ChatDetails';
import Services from './Services';
import Careers from './Careers';
import AddCareer from './AddCareer';

function App() {
  return (
    
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <Home></Home>
          </Route>
          <Route path="/main">
            <Main></Main>
          </Route>
          <Route path="/create">
            <Create></Create>
          </Route>
          <Route path="/user_list">
            <UserList></UserList>
          </Route>
          <Route path="/chat_list">
            <ChatList></ChatList>
          </Route>
          <Route path="/user_details/:id">
            <UserDetails></UserDetails>
          </Route>
          <Route path="/property_list/home" exact>
            <PropertyListHome></PropertyListHome>
          </Route>
          <Route path="/property_list/apartment" exact>
            <PropertyListApartment></PropertyListApartment>
          </Route>
          <Route path="/property_list/commercial" exact>
            <PropertyListCommercial></PropertyListCommercial>
          </Route>
          <Route path="/chat_details/:sender_id/:receiver_id">
            <ChatDetails></ChatDetails>
          </Route>
          <Route path="/add_user">
            <AddUser></AddUser>
          </Route>
          <Route path="/blogs/:id">
            <BlogDetails></BlogDetails>
          </Route>
          <Route path="/property_list/">
            <PropertyList></PropertyList>
          </Route>
          <Route path="/property/:id">
            <PropertyDetails></PropertyDetails>
          </Route>
          <Route path="/propertyopen/:id">
            <PropertyDetailsOpen></PropertyDetailsOpen>
          </Route>
          <Route path="/add_property">
            <AddProperty></AddProperty>
          </Route>
          <Route path="/services">
            <Services></Services>
          </Route>
          <Route path="/careers">
            <Careers></Careers>
          </Route>
          <Route path="/add_career">
            <AddCareer></AddCareer>
          </Route>
          <Route path="*">
            <NotFound></NotFound>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
