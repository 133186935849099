import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

function Create() {
    const [title,setTitle] = useState('');
    const [author, setAuthor] = useState('');
    const [isPending, setIsPending] = useState(false);
    const history = useHistory();

    const handleSubmit = (e) => {
        e.preventDefault();
        const blog = {title, author};

        setIsPending(true);

        fetch('http://localhost:3303/blogs/',{
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(blog),
        })
        .then(() => { 
            console.log("new blog added");
            setIsPending(false);
            history.push('/');
        });
    }

    return (
        <div>
            <h2>Add a new blog</h2>
            <form onSubmit={handleSubmit}>
                <label>Blog Title</label>
                <br></br>
                <input 
                    type="text"
                    required
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <br></br>
                <label>Blog Author</label>
                <br></br>
                <select
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                >
                    <option value="Author 1">Author 1</option>
                    <option value="Author 2">Author 2</option>
                    <option value="Author 3">Author 3</option>
                </select>
                <br></br><br></br>
                { !isPending && <button>Add Blog</button>}
                { isPending && <button disabled>Adding Blog...</button> }
            </form>
        </div>
    );
}

export default Create;