import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import BlogList from './BlogList';
import useFetch from './useFetch';
import { Link } from 'react-router-dom';

function NotFound() {
    return (
        <div>
            <h2>404 Page not found</h2>
            <Link to="/">Go To Home Page</Link>
        </div>
    );
}

export default NotFound;