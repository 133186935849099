import './App.css';
import Sidebar from './Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCookies } from 'react-cookie';
import './Main.css';
import { FaSearch } from "react-icons/fa";
import Background from './Background';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

function Careers() {

    const [cookies, setCookie] = useCookies(['user']);
    const [careers,setCareers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getCareers = () => {
        var data = new FormData();
        
        data.append("user_id",11);
        setIsLoading(true);
        fetch('https://api.connecker.com/api/get_careers', {
            method: 'POST',
            body: data
        }).then(res => {
            setIsLoading(false);
            if(!res.ok) {
                throw Error('Could not fetch');
            }
            return res.json();
         }).then(data => {
            setIsLoading(false);
            if(data.response_code == 200) {
               setCareers(data.data);
            } else if(data.response_code == 404) {
                setCareers([]);
            }
         });
    }

    const deleteCareerAPI = (career_id) => {
        var data = new FormData();
        
        data.append("career_id",career_id);
        setIsLoading(true);
        fetch('https://api.connecker.com/api/delete_career', {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': `Bearer ${cookies.token}`
            }
        }).then(res => {
            setIsLoading(false);
            if(!res.ok) {
                throw Error('Could not fetch');
            }
            return res.json();
         }).then(data => {
            setIsLoading(false);
            if(data.response_code == 200) {
                toast(data.message);
                getCareers();
            } else if(data.response_code == 404) {
                toast(data.message);
            }
         });
    }

    const deleteCareer = (career_id) => {
        // Display a confirmation dialog
        const result = window.confirm('Are you sure you want to proceed?');
    
        // Check the result of the confirmation dialog
        if (result) {
        // User clicked OK, proceed with the action
        deleteCareerAPI(career_id);
        } else {
        // User clicked Cancel, do nothing or handle accordingly
        console.log('User canceled');
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        getCareers();
       },[]);

    return(
        <div className="content">
            <div className="row justify-content-start">
                <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                    <Sidebar></Sidebar>
                </div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                    <Background></Background>
                    <h4 className="heading" style={{ color:"white", marginTop:"50px" }}>Careers</h4>
                    <div className="card" style={{borderColor:"white"}}>
                        <div className="table-responsive">
                            <table style={{width:"100%"}} className="table table-borderless">
                                <thead>
                                    <tr className="paragraph" style={{ color:"grey" }}>
                                        <td>
                                            <b>#</b>
                                        </td>
                                        <td>
                                            <b>Title</b>
                                        </td>
                                        <td>
                                            <b>Experience</b>
                                        </td>
                                        <td>
                                            <b>Type</b>
                                        </td>
                                        <td>
                                            <b>Description</b>
                                        </td>
                                        <td>
                                            <b>Actions</b>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        careers.map((career, i) => (
                                                <tr className="paragraph">
                                                    <td>
                                                        {i+1}
                                                    </td>
                                                    <td>
                                                        {career.title}
                                                    </td>
                                                    <td>
                                                        {career.experience}
                                                    </td>
                                                    <td>
                                                        {career.type}
                                                    </td>
                                                    <td>
                                                        {career.description}
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-danger" onClick={() => deleteCareer(career.id)}>Delete</button>
                                                    </td>
                                                </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Careers;