import './App.css';
import Sidebar from './Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCookies } from 'react-cookie';
import './Main.css';
import { FaSearch } from "react-icons/fa";
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import conneckerLogo4 from './images/connecker_logo_4.png';

function Background() {

    const [cookies, setCookie] = useCookies(['user']);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    const search = (e) => {
        if(e.keyCode == "13" || e.charCode == "13") {
            console.log(e.target.value);
            e = e.target.value;
            e = e.toLowerCase();
            if(e == "chats" || e == "chat") {
                history.push('/chat_list');
            } else if(e == "properties" || e == "property list" || e == "property lists") {
                history.push('property_list');
            } else {
                toast("Not Found");
            }
        }
    }

    return (
        <div class="content">
            <div class="row">
                <div class="col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                    <img style={{ paddingTop:"10px" }} width="100" height="auto" src={conneckerLogo4}></img>
                </div>
                <div class="col-md-8 col-lg-8 col-xl-8 col-xxl-8 mt-sm-3">
                <div class="d-flex" style={{ border:"1px solid #FFF", borderRadius:"50px", marginTop:"10px" }}>
                    <input style={{ color:"black", border:"none", borderRadius:"50px" }}  type="text" id="searchInput" class="form-control subHeading" placeholder='Search...' onKeyPress={(e) => search(e)}/>
                </div>
                </div>
                <div class="col-md-2 col-lg-2 col-xl-2 col-xxl-2"></div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default Background;