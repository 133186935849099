import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar';
//import 'react-pro-sidebar/dist/css/styles.css';
import './custom.scss';
import { Link } from 'react-router-dom';
import { FaHome, FaArrowRight, FaCog, FaBuilding, FaUserCog, FaMailBulk, FaSignOutAlt, FaAngleDoubleLeft, FaAngleDoubleRight, FaBars } from "react-icons/fa";
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';

function Sidebar() {
    const [cookies, setCookie] = useCookies(['user']);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const history = useHistory();

    const collapseSidebar = () => {
      setSidebarCollapsed(!sidebarCollapsed);
    }   

    useEffect(() => {
        const handleResize = () => {
            const isSmallScreen = window.innerWidth <= 1800; // Adjust breakpoint as needed
            setIsMobile(isSmallScreen);
            console.log("Resizing happening!")
            console.log("isMobile Value is" + isSmallScreen)
            console.log(window.innerWidth)
            if(isSmallScreen) {
                setSidebarCollapsed(true)
            } else {
                setSidebarCollapsed(false)
            }
        };
      
        window.addEventListener('resize', handleResize);
        handleResize();
        const abortController = new AbortController();
        if(cookies.admin == "") {
            history.push('/');
        }
        return () => window.removeEventListener('resize', handleResize);
    },[]);

    return (
        <ProSidebar collapsed={sidebarCollapsed} style={{ position:"fixed" }}>
            <button className="floating-button">
                <FaBars onClick={() => collapseSidebar()}/>
            </button>
            <SidebarHeader>
                    <div style={{ backgroundColor:"white",marginBottom:"0px", paddingBottom:"10px" }}>
                        <center>
                {/* {!sidebarCollapsed ? <FaAngleDoubleLeft onClick={() => collapseSidebar()}></FaAngleDoubleLeft> : <FaAngleDoubleRight onClick={() => collapseSidebar()}></FaAngleDoubleRight>} */}
                <br></br>
            {!sidebarCollapsed ? <img src={`${cookies.profile_pic}`} className="rounded-circle" style={{width:"150px", height:"150px", padding:"20px"}} /> : <div></div>}
                            { cookies.middle_name != 'null' ? <h6 className="paragraph">{cookies.first_name + " " + cookies.middle_name + " " + cookies.last_name}</h6> : <div></div>}
                            { cookies.middle_name == 'null' ? <h6 className="paragraph">{cookies.first_name + " " + cookies.last_name}</h6> : <div></div>}
                        </center>
                    </div>
            </SidebarHeader>
            <SidebarContent>
                <Menu iconShape="square">
                    <MenuItem icon={<FaHome />}>
                        <Link to="/main">Dashboard</Link>
                    </MenuItem>
                    <SubMenu title="Property Management" icon={<FaCog />}>
                        <MenuItem icon={<FaArrowRight/>} className="paragraph" >Property List <Link to="/property_list/" /></MenuItem>
                        <MenuItem icon={<FaArrowRight/>} className="paragraph" >Add Property <Link to="/add_property" /></MenuItem>
                    </SubMenu>
                    <SubMenu title="Types" icon={<FaBuilding/>}>
                        <MenuItem icon={<FaArrowRight/>} className="paragraph" >Home <Link to="/property_list/home" /></MenuItem>
                        <MenuItem icon={<FaArrowRight/>} className="paragraph" >Apartment <Link to="/property_list/apartment" /></MenuItem>
                        <MenuItem icon={<FaArrowRight/>} className="paragraph" >Commercial <Link to="/property_list/commercial" /></MenuItem>
                    </SubMenu>
                    <SubMenu title="User Management" icon={<FaUserCog />}>
                        <MenuItem icon={<FaArrowRight/>} className="paragraph" >All Users <Link to="/user_list/" /></MenuItem>
                        <MenuItem icon={<FaArrowRight/>} className="paragraph" >Add User <Link to="/add_user" /></MenuItem>
                    </SubMenu>
                    <MenuItem icon={<FaMailBulk />}>
                        <Link to="/chat_list">Chats</Link>
                    </MenuItem>
                    <MenuItem icon={<FaMailBulk />}>
                        <Link to="/services">Services</Link>
                    </MenuItem>
                    <SubMenu title="Career Management" icon={<FaCog />}>
                        <MenuItem icon={<FaArrowRight/>}>Careers<Link to="/careers" /></MenuItem>
                        <MenuItem icon={<FaArrowRight/>}>Add Career<Link to="/add_career" /></MenuItem>
                    </SubMenu>
                    <MenuItem icon={<FaSignOutAlt style={{ color:"red" }} />}>
                        <Link to="/">Sign Out</Link>
                    </MenuItem>
                </Menu>
            </SidebarContent>
        </ProSidebar>
    );
}

export default Sidebar
