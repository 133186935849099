import './App.css';
import Sidebar from './Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCookies } from 'react-cookie';
import './Main.css';
import { FaMapMarkerAlt, FaMicrosoft, FaBed, FaCarAlt, FaHome, FaCheckCircle, FaCloudUploadAlt } from "react-icons/fa";
import { useParams, useHistory } from 'react-router-dom';
import Background from './Background';
import { useState, useEffect, useCallback } from 'react';
import './TableStyle.css';
import { MapContainer, TileLayer } from 'react-leaflet';
import {useDropzone} from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';
import React from 'react';

function AddUser() {
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        acceptedFiles.forEach((file) => {
            setImageFile(file);
            const reader = new FileReader()
      
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.addEventListener('load', (e) => {
                setProfilePic(e.target.result);
            });
            reader.readAsDataURL(file);
          })
      }, [])
    const inputRef = React.createRef();
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
    const [profilePic,setProfilePic] = useState(null);
    const [firstName,setFirstName] = useState("");
    const [middleName,setMiddleName] = useState("");
    const [lastName,setLastName] = useState("");
    const [dob,setDob] = useState("");
    const [email,setEmail] = useState("");
    const [mobile,setMobile] = useState("");
    const [password,setPassword] = useState("");
    const [confirmPassword,setConfirmPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [imageFile,setImageFile] = useState(null);

    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero for single-digit months
        const day = String(date.getDate()).padStart(2, '0'); // Add leading zero for single-digit days
      
        return `${year}-${month}-${day}`;
      }

    function validateAge(birthDate) {
    const eighteenYearsInMilliseconds = 18 * 365.25 * 24 * 60 * 60 * 1000;
    const today = new Date();
    const ageInMilliseconds = today.getTime() - birthDate.getTime();
    
    return ageInMilliseconds >= eighteenYearsInMilliseconds;
    }

    const handleDateChange = (event) => {
        const selectedDate = event.target.value;
        console.log(selectedDate);
        const formattedDate = formatDate(selectedDate);
        const isValidAge = validateAge(formattedDate);
        if (!isValidAge) {
            toast("User must be 18 years or older.");
        } else {
            setDob(formattedDate);
        }
      }

    const register = () => {
        var data = new FormData();
        console.log("The button pressed");
        if(firstName == "") {
            toast("Enter first name");
        } else if(lastName == "") {
            toast("Enter last name");
        } else if(email == "") {
            toast("Enter email");
        } else if(mobile == "") {
            toast("Enter mobile");
        } else if(dob == "") {
            toast("Enter dob");
        }
        else if(password != confirmPassword) {
            toast("Password and Confirm Password should be same");
        } else {
            data.append("first_name",firstName);
            data.append("middle_name",middleName);
            data.append("last_name",lastName);
            data.append("dob",dob);
            data.append("email",email);
            data.append("mobile",mobile);
            data.append("password",password);
            data.append("confirm_password",confirmPassword);
            if(imageFile != null) {
                data.append("profile_pic",imageFile);
            }
            setIsLoading(true);
            fetch('https://api.connecker.com/api/register', {
                method: 'POST',
                body: data
            }).then(res => {
                setIsLoading(false);
                if(!res.ok) {
                    throw Error('Could not fetch');
                }
                return res.json();
            }).then(async(data) => {
                setIsLoading(false);
                if(data.response_code == 200) {
                    toast(data.message);
                } else if(data.response_code == 404) {
                    toast(data.message);
                } else {
                    toast(data.message);
                }
            });
        }
    }

    return(
        <div className="content">
            <div className="row d-flex justify-content-center">
                <div className="col-2 col-sm-2">
                    <Sidebar></Sidebar>
                </div>
                <div className="col-10 col-sm-10">
                    <Background></Background>
                    <h4 style={{ color:"white", marginTop:"50px" }}>Add User</h4>
                    <div class="card" style={{width:"100%"}}>
                        <div class="card-body">
                            <div className="d-flex">
                                <h6 style={{ color:"orange" }}>Basic</h6>
                                &nbsp;
                                <h6>Information</h6>
                            </div>
                            <br></br>
                            <input type="text" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px", width:"17rem" }} onChange={(e) => setFirstName(e.target.value)} placeholder="First Name"></input>
                            <input type="text" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px", width:"17rem" }} onChange={(e) => setMiddleName(e.target.value)} placeholder="Middle Name"></input>
                            <input type="text" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px", width:"17rem" }} onChange={(e) => setLastName(e.target.value)} placeholder="Last Name"></input>
                            <input type="date" ref={inputRef} style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px", width:"17rem" }} onChange={(e) => setDob(e.target.value)} placeholder="Date Of Birth"></input>
                            <input type="text" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px", width:"17rem" }} onChange={(e) => setEmail(e.target.value)} placeholder="Email"></input>
                            <input type="text" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px", width:"17rem" }} onChange={(e) => setMobile(e.target.value)} placeholder="Phone Number"></input>
                            <input type="password" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px", width:"17rem" }} onChange={(e) => setPassword(e.target.value)} placeholder="Password"></input>
                            <input type="password" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px", width:"17rem" }} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password"></input>
                            <br></br><br></br>
                            <img src={profilePic} style={{ width:"50px" }}></img>
                            <br></br><br></br>
                            <div {...getRootProps()} class="container" style={{ borderRadius:"16px", height:"12rem", backgroundColor:"#FCC981" }}>
                                <input {...getInputProps()} />
                                {
                                    !isDragActive ?
                                    <center>
                                            <FaCloudUploadAlt style={{ width:"5rem", height:"5rem", color:"#FF4500" }}></FaCloudUploadAlt>
                                            <br></br><br></br>
                                            <h5><b>Drop Files Or Click To Upload</b></h5>
                                    </center>
                                    :
                                    <div>
                                    </div>
                                }
                            </div>
                            <br></br>
                            <button onClick={() => register()} style={{ color:"white",backgroundColor:"#FF4500",border:"1px solid #FF4500",borderRadius:"25px",padding:"10px",paddingLeft:"40px",paddingRight:"40px",margin:"5px" }}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default AddUser;