export const getDataForPropertiesByMonth = (data) => {

    var graphDataArray = [0,0,0,0,0,0,0,0,0,0,0,0];

    data.forEach((item,i) => {
        if(item.month_name == "January") {
            graphDataArray[0] = item.count;
        }
        if(item.month_name == "February") {
            graphDataArray[1] = item.count;
        }
        if(item.month_name == "March") {
            graphDataArray[2] = item.count;
        }
        if(item.month_name == "April") {
            graphDataArray[3] = item.count;
        }
        if(item.month_name == "May") {
            graphDataArray[4] = item.count;
        }
        if(item.month_name == "June") {
            graphDataArray[5] = item.count;
        }
        if(item.month_name == "July") {
            graphDataArray[6] = item.count;
        }
        if(item.month_name == "August") {
            graphDataArray[7] = item.count;
        }
        if(item.month_name == "September") {
            graphDataArray[8] = item.count;
        }
        if(item.month_name == "October") {
            graphDataArray[9] = item.count;
        }
        if(item.month_name == "November") {
            graphDataArray[10] = item.count;
        }
        if(item.month_name == "December") {
            graphDataArray[11] = item.count;
        }
    });

    return graphDataArray;
  };