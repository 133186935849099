import './App.css';
import Sidebar from './Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCookies } from 'react-cookie';
import './Main.css';
import { FaMapMarkerAlt, FaMicrosoft, FaBed, FaCarAlt, FaHome, FaCheckCircle, FaCross, FaTimes } from "react-icons/fa";
import { useParams, useHistory } from 'react-router-dom';
import Background from './Background';
import { useState, useEffect } from 'react';
import './TableStyle.css';
import { MapContainer, TileLayer } from 'react-leaflet';
import { useNavigate } from 'react-router-dom';

function ChatDetails() {
    const { sender_id } = useParams();
    const { receiver_id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [chatDetails,setChatDetails] = useState(null);
    const [message, setMessage] = useState("");
    const history = useHistory();

    const scrollToBottom = () => {
  	this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }

    const getMessages = () => {
        var data = new FormData();
        data.append("sender_id",sender_id);
        data.append("receiver_id",receiver_id);
        setIsLoading(true);
        fetch('https://api.connecker.com/api/get_messages', {
            method: 'POST',
            body: data
        }).then(res => {
            setIsLoading(false);
            if(!res.ok) {
                throw Error('Could not fetch');
            }
            return res.json();
         }).then(async(data) => {
            setIsLoading(false);
            if(data.response_code == 200) {
                if(chatDetails != null) {
                	if(chatDetails.length != data.data.length) {
	         		scrollToBottom();
			}
                }
                setChatDetails(data.data);
            } else if(data.response_code == 404) {
                setChatDetails({});
            }
         });
    }


        

const handleXButtonClick = () => {
    history.push('/chat_list');
}

    const sendMessage = () => {
        var data = new FormData();
        data.append("sender_id",11);
        if(sender_id != 11) {
            data.append("receiver_id",sender_id);
        } else {
            data.append("receiver_id",receiver_id);
        }
        data.append("message",message);
        setIsLoading(true);
        fetch('https://api.connecker.com/api/send_message', {
            method: 'POST',
            body: data
        }).then(res => {
            setIsLoading(false);
            if(!res.ok) {
                throw Error('Could not fetch');
            }
            setMessage("");
            return res.json();
         }).then(async(data) => {
            setIsLoading(false);
            if(data.response_code == 200) {
                setChatDetails(data.data);
                scrollToBottom();
            } else if(data.response_code == 404) {
                setChatDetails({});
            }
         });
    }

    useEffect(() => {
        const abortController = new AbortController();
        setInterval(() => {
            getMessages();
        }, 9000);
    },[]);

    return(
        <div className="content">
            <div className="row justify-content-start">
                <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                    <Sidebar></Sidebar>
                </div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                        <Background></Background>
                        
                        <h4 className="heading" style={{ color:"white", marginTop:"50px" }}>Chat</h4>
                                <div class="card" style={{width:"100%", paddingBottom:"50px"}}>
                                    
                                    <div class="card-body paragraph">
                                        
                                            <FaTimes onClick={() => handleXButtonClick()} style={{float:"right", margin:"10px", marginBottom:"20px"}} ></FaTimes>
                                        
                                    {
                                        chatDetails != null ? chatDetails.map((chat, i) => (
                                                chat.sender_id == 11 ? <div><p style={{ float:"right",border:"1px solid lightgreen",borderRadius:"16px",backgroundColor:"lightgreen",color:"black",padding:"20px" }}>{ chat.message }</p><br></br><br></br><br></br><br></br></div> : <div><p style={{ float:"left", border:"1px solid lightblue", borderRadius:"16px",backgroundColor:"lightblue", padding:"20px", color:"black" }}>{ chat.message }</p><br></br><br></br><br></br><br></br></div>
                                        )) : <div></div>
                                    }
                                    </div>
                                </div>
                        <div id="messageEnd" style={{ float:"left", clear: "both" }}>
                        </div>
                        <div className="w-100" style={{ position:"fixed", height:"5rem",bottom:"0",border:"1px solid lightgrey",borderRadius:"16px",backgroundColor:"white"}}>
                                <input className="w-50" type="text" style={{ border:"1px solid lightgrey",borderRadius:"25px",padding:"10px",margin:"5px" }} onChange={(e) => setMessage(e.target.value)} placeholder="Enter Message" value={message}></input>                           
                                <button onClick={sendMessage} style={{ color:"white",backgroundColor:"#FF4500",border:"1px solid #FF4500",borderRadius:"25px",marginTop:"5px", padding:"10px", paddingLeft:"20px", paddingRight:"20px"}}>Send</button>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default ChatDetails;
